import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthenticationStatus } from "@nhost/react";

function ProtectedRoute({ component: Component, ...restOfProps }) {
  const { isAuthenticated, isLoading } = useAuthenticationStatus()

  return (
    <Route
      {...restOfProps}
      render={(props) =>
        isLoading ?
        <div className="text-center">
        <div
        className='spinner-border spinner-border-sm mt-90 ml-auto mr-auto'
        role='status'
        aria-hidden='true'
      ></div>
      </div>
      : 
      isAuthenticated ? 
      <Component {...props} /> 
      : 
      <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;