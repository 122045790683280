import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { NhostReactProvider } from "@nhost/react";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { nhost } from "./utils/nhost";
import Routers from "./Routers";
import { loadLanguages } from "redux-multilanguage";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { save, load } from "redux-localstorage-simple";
import { Provider } from "react-redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { ToastProvider } from "react-toast-notifications";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import rootReducer from "./redux/reducers/rootReducer";
import AppContextProvider from "./auth/auth-context";
import { fetchProducts } from "./redux/actions/productActions";
import { signIn, signOut } from "./redux/actions/sessionActions";

function App() {
  const [error, setError]  = useState(null)

  const store = createStore(
    rootReducer,
    load(),
    composeWithDevTools(applyMiddleware(thunk, save()))
  );

  useEffect(() => {
    store.dispatch(
      loadLanguages({
        languages: {
          en: require("./translations/english.json"),
          fn: require("./translations/french.json"),
          de: require("./translations/germany.json")
        }
      })
    );
  },[store]);

  useEffect(()=>{
    nhost.auth.onAuthStateChanged(( event, session ) => {
      // event = 'SIGNED_IN' | 'SIGNED_OUT'
      if(event==="SIGNED_IN")store.dispatch(signIn(session))
      if(event==="SIGNED_OUT")store.dispatch(signOut())
    })},[])

  const query = `query GetProducts {
    products(where: {isActive: {_eq: true}}, order_by: {created_at: asc}) {
      discount
      fullDescription
      price
      id
      name
      type
      isActive
      saleCount
      shortDescription
      urlText
      image
      dates(order_by: {created_at: asc}, where: {jsDate: {_gt: now}}) {
        date
        created_at
        id
        product_id
        seats
        updated_at
      }
      modules {
        name
        position
        id
        contents {
          id
          position
          name
          duration {
            content_id
            duration
          }
        }
      }
    }
  }`

  useEffect(()=>{
    const getProducts = async () => {
      const { data, error } = await nhost.graphql.request(query)
      if(error)setError(error);
      if(data)store.dispatch(fetchProducts(data.products))
    }
    getProducts()
  },[query, store])

  return (
    <NhostReactProvider nhost={nhost}>
      <NhostApolloProvider nhost={nhost}>
        <Provider store={store}>
          <ToastProvider placement='bottom-right'>
            <BreadcrumbsProvider>
              <AppContextProvider>
                <Routers/>
              </AppContextProvider>
            </BreadcrumbsProvider>
          </ToastProvider>
        </Provider>
      </NhostApolloProvider>
    </NhostReactProvider>
  );
}

App.propTypes = {
  dispatch: PropTypes.func,
};

export default App;
