import React, { useEffect } from "react";

export const AppContext = React.createContext();
export const AppDispatch = React.createContext();

const initialState = {
  address: null,
  dates: null,
  student: false,
  earlyBird: []
};
 
const reducer = (state, action) => {
  switch (action.type) {
    case "DATES":
      return {
        ...state,
        dates: action.payload,
      };

      case "STUDENT":
        return {
          ...state,
          student: action.payload,
        };

    case "ADDRESS":
      return {
        ...state,
        address: action.payload,
      };

      case "EARLY_BIRD":
        return {
          ...state,
          earlyBird: action.payload,
        };

    default:
      return state;
  }
};

const AppContextProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState, () => {
    const address = localStorage.getItem('address') ? JSON.parse(localStorage.getItem('address')) : null
    const dates = localStorage.getItem('dates') ? JSON.parse(localStorage.getItem('dates')) : {}
    const student = localStorage.getItem('student') ? JSON.parse(localStorage.getItem('student')) : null
    return {address, dates, student}
  });

  useEffect(()=>{
    localStorage.setItem('address', JSON.stringify(state.address))
  }, [state.address])


  return (
    <AppContext.Provider value={state}>
      <AppDispatch.Provider value={dispatch}>{children}</AppDispatch.Provider>
    </AppContext.Provider>
  );
};

export const useAppState = () => {
  const context = React.useContext(AppContext);
  if (context === undefined) {
    throw new Error(
      "useNewCampaignState must be used within a NewCampaignContextProvider"
    );
  }
  return context;
};

export const useAppDispatch = () => {
  const context = React.useContext(AppDispatch);
  if (context === undefined) {
    throw new Error(
      "useNewCampaignDispatch must be used within a NewCampaignContextProvider"
    );
  }
  return context;
};

export default AppContextProvider;