import React, { useState } from 'react';
import { Link } from "react-router-dom";
import { gql, useMutation } from '@apollo/client';

export default function Unsubscribe(props){
    const [unsubscribed, setUnsubscribed] = useState(false)
    const  email  = props.match.params.email
    const [unsub, {loading, error}] = useMutation(gql`
    mutation insertUnsuber($email: String!) {
        insert_unsubers(objects: {email: $email}) {
          affected_rows
        }
      }`, {onCompleted(){
        setUnsubscribed(true)
      }
      })

      function handleUnsub(e){
          e.preventDefault();
          unsub({
              variables: {
                  email: email
              }
          })
      }

      if(error)console.log(error)

    return(
        <div className="welcome-area 100 100">
        <div className="container mt-5">
                    <div className="welcome-content text-center">
            <h1>
            Email Listesinden Çık
            </h1>
            <p>
                <b>{email}</b>
            </p>
            <p>
                <h5 className="mt-1">
                Orginsight&Co. OD Akademi email listesinden çıkmak istedğinize emin misiniz?
                </h5>
            </p>
            <div className="mt-5 border-bottom pb-5">
            <button 
            className="btn btn-primary"
            onClick={(e)=>handleUnsub(e)}
            disabled={unsubscribed}
            >
            {loading?
                    <span
                    className='spinner-border'
                    role='status'
                    aria-hidden='true'
                ></span>
                :"Onayla"
                }
            </button>
            <Link to={process.env.PUBLIC_URL}>
            <button className="btn btn-secondary ml-2">İptal</button>
            </Link>
            {unsubscribed? <p className="text-success mt-2">Email listesinden başarıyla çıktınız.</p>: error? <p className="text-danger mt-2">Bir hata oluştu, tekrar deneyiebilir veya bizimle iletişime geçeblirsiniz.</p>: null}
            </div>
            <div className="mt-5">
                <p>
                    Orginsight&Co. OD Akademi'de bulunan serbest zamanlı ve canlı eğitimleri aşağıdaki linke tıklayarak görebilirsiniz.
                </p>
                <Link to={process.env.PUBLIC_URL}>
                    <button className="btn btn-success">
                    Eğitimler
                    </button>
                </Link>
            </div>
            </div>
            </div>
        </div>
    )
}