export const SIGNED_IN = "SIGNED_IN";
export const SIGNED_OUT = "SIGNED_OUT";

export const signIn = (session) => {
    return ({ type: SIGNED_IN, payload: session });

};

export const signOut = (session) => {
   return ({ type: SIGNED_OUT, payload: null });
};
