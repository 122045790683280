import { SIGNED_IN, SIGNED_OUT } from "../actions/sessionActions";

const initState = {
  session: null
};

const sessionReducer = (state = initState, action) => {
  if (action.type === SIGNED_IN) {
    return {
      ...state,
      session: action.payload
    };
  }
  if (action.type === SIGNED_OUT) {
    return {
      ...state,
      session: null
    };
  }

  return state;
};

export default sessionReducer;
