import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./helpers/scroll-top";
import ManagerRoute from "./ManagerRoute";
import Unsubscribe from "./pages/managerZone/Unsubscribe";
import ProtectedRoute from "./ProtectedRoute";

// home page
const HomeFashion = lazy(() => import("./pages/home/HomeFashion"));

// shop pages
const ShopGridTwoColumn = lazy(() => import("./pages/shop/ShopGridTwoColumn"));

// product pages
const Product = lazy(() => import("./pages/shop-product/Product"));

// other pages
const About = lazy(() => import("./pages/other/About"));
const Atelier = lazy(() => import("./pages/other/Atelier"));
const Contact = lazy(() => import("./pages/other/Contact"));
const Signin = lazy(() => import("./pages/other/Signin"));
const Signup = lazy(() => import("./pages/other/Signup"));
const ResetPassword = lazy(() => import("./pages/other/ResetPassword"));
const ChangePassword = lazy(() => import("./pages/other/ChangePassword"));
const ChangeEmail = lazy(() => import("./pages/other/ChangeEmail"));
const PaymentSuccess = lazy(() => import("./pages/other/PaymentSuccess"));
const Learn = lazy(() => import("./pages/learn/Learn"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const Account = lazy(() => import("./pages/account/Account"));
const Orders = lazy(() => import("./pages/managerZone/Orders"));
const Dashboard = lazy(() => import("./pages/managerZone/Dashboard"));
const Recruit = lazy(() => import("./pages/managerZone/recruit/Recruit"));
const ProductManage = lazy(() => import("./pages/managerZone/ProductManage"));
const Modules = lazy(() => import("./pages/managerZone/learning/Modules"));

const Guvenlik = lazy(() => import("./pages/documents/Guvenlik"));
const Gizlilik = lazy(() => import("./pages/documents/Gizlilik"));
const Kullanim = lazy(() => import("./pages/documents/Kullanim"));
const Cerezler = lazy(() => import("./pages/documents/Cerezler"));
const Kvkk = lazy(() => import("./pages/documents/Kvkk"));
const Returns = lazy(() => import("./pages/documents/Returns"));

const NetworkError = lazy(() => import("./pages/other/NetworkError"));
const SomethingWentWrong = lazy(() =>
  import("./pages/other/SomethingWentWrong")
);

const NotFound = lazy(() => import("./pages/other/NotFound"));

function Routers() {
  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path={process.env.PUBLIC_URL + "/"}
              component={HomeFashion}
            />

            {/* Homepages */}

            {/* Shop pages */}
            <Route
              path={process.env.PUBLIC_URL + "/shop"}
              component={ShopGridTwoColumn}
            />

            {/* Shop product pages */}
            <Route
              path={process.env.PUBLIC_URL + "/course/:urlText"}
              render={(routeProps) => (
                <Product
                  {...routeProps}
                  key={routeProps.match.params.urlText}
                />
              )}
            />

            {/* Other pages */}
            <Route path={process.env.PUBLIC_URL + "/about"} component={About} />
            <Route
              path={process.env.PUBLIC_URL + "/atolye"}
              component={Atelier}
            />

            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              component={Contact}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login"}
              component={Signin}
            />
            <Route
              path={process.env.PUBLIC_URL + "/register"}
              component={Signup}
            />
            <Route
              path={process.env.PUBLIC_URL + "/reset-password"}
              component={ResetPassword}
            />
            <ProtectedRoute
              path={process.env.PUBLIC_URL + "/change-password"}
              component={ChangePassword}
            />
            <ProtectedRoute
              path={process.env.PUBLIC_URL + "/change-email"}
              component={ChangeEmail}
            />
            <Route path={process.env.PUBLIC_URL + "/cart"} component={Cart} />

            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              component={Checkout}
            />
            <ProtectedRoute
              path={process.env.PUBLIC_URL + "/learn/:id"}
              component={Learn}
            />

            <Route
              path={process.env.PUBLIC_URL + "/od-network"}
              component={Account}
            />

            <ManagerRoute
              path={process.env.PUBLIC_URL + "/orders"}
              component={Orders}
            />

            <ManagerRoute
              path={process.env.PUBLIC_URL + "/modules/:id"}
              component={Modules}
            />

            <ManagerRoute
              path={process.env.PUBLIC_URL + "/dashboard"}
              component={Dashboard}
            />

            <ManagerRoute
              path={process.env.PUBLIC_URL + "/recruit"}
              component={Recruit}
            />

            <Route
              path={process.env.PUBLIC_URL + "/success/order/:id"}
              component={PaymentSuccess}
            />

            <Route
              path={process.env.PUBLIC_URL + "/guvenlik"}
              component={Guvenlik}
            />

            <Route
              path={process.env.PUBLIC_URL + "/gizlilik"}
              component={Gizlilik}
            />

            <Route
              path={process.env.PUBLIC_URL + "/kosullar"}
              component={Kullanim}
            />

            <Route
              path={process.env.PUBLIC_URL + "/cerezler"}
              component={Cerezler}
            />

            <Route
              path={process.env.PUBLIC_URL + "/iade"}
              component={Returns}
            />

            <Route path={process.env.PUBLIC_URL + "/kvkk"} component={Kvkk} />

            <Route
              path={process.env.PUBLIC_URL + "/somethingwentwrong"}
              component={SomethingWentWrong}
            />

            <Route
              path={process.env.PUBLIC_URL + "/networkerror"}
              component={NetworkError}
            />

            <Route
              path={process.env.PUBLIC_URL + "/not-found"}
              component={NotFound}
            />

            <Route
              path={process.env.PUBLIC_URL + "/productmanage"}
              component={ProductManage}
            />

            <Route
              path={process.env.PUBLIC_URL + "/unsubscribe/:email"}
              render={(routeProps) => (
                <Unsubscribe {...routeProps} key={routeProps.match.params.id} />
              )}
            />

            <Route exact component={NotFound} />
          </Switch>
        </Suspense>
      </ScrollToTop>
    </Router>
  );
}

export default Routers;
